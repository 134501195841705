<template>
    <div>
        <el-dialog
            title="选择范围"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <el-form ref="form" :model="form" label-position="left" label-width="100px" v-if="step == 1">
                <el-form-item prop="mark" label="省市区">
                    <le-input-district-choose ref="districtChoose" style="padding-top: 0;padding-bottom: 0;"
                        :province.sync="form.provinceCode" 
                        :city.sync="form.cityCode" 
                        :area.sync="form.areaCode"
                        large></le-input-district-choose>
                </el-form-item>
                <el-form-item prop="mark" label="商户">
                    <le-company-under-select style="padding-top: 0;padding-bottom: 0;width: 400px;"
                        v-model="form.company"
                        large
                        isObj
                        placeholder="请选择商户（可输入搜索）"></le-company-under-select>
                </el-form-item>
                <el-form-item prop="mark" label="项目">
                    <le-select-remote-search style="padding-top: 0;padding-bottom: 0;width: 400px;"
                        large
                        isObj
                        :readonly="form.company?false:true"
                        v-model="form.income" 
                        :options="optionsincomeDistri" 
                        placeholder="请选择项目（可输入搜索）" />
                </el-form-item>
                <el-form-item prop="mark" label="站点">
                    <le-select-remote-search style="padding-top: 0;padding-bottom: 0;width: 400px;"
                        large
                        isObj
                        :readonly="form.income?false:true"
                        v-model="form.station" 
                        :options="optionsStation" 
                        placeholder="请选择站点（可输入搜索）" />
                </el-form-item>
            </el-form>
            <el-form ref="form" label-position="left" label-width="100px" v-if="step == 2">
                <el-form-item prop="mark" label-width="0">
                    <div class="a-flex-cfsfs">
                        <span class="a-fs-16 a-fw-700">群组范围</span>
                        <span class="rangeName" v-if="groupItem.regionName">省市区：{{ groupItem.regionName }}</span>
                        <span class="rangeName" v-if="groupItem.companyName">商户：{{ groupItem.companyName }}</span>
                        <span class="rangeName" v-if="groupItem.incomeName">项目：{{ groupItem.incomeName }}</span>
                        <span class="rangeName" v-if="groupItem.stationName">站点：{{ groupItem.stationName }}</span>
                    </div>
                </el-form-item>
                <el-form-item prop="mark" label="群组人员">
                    <el-input @keyup.enter.native="addUser" style="width: 300px;margin-right: 12px;" v-model="phone" maxlength="20" placeholder="请输入通知人手机号"></el-input>
                    <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="addUser">添加</el-button>
                    <div class="a-flex-rfsc a-flex-wrap a-mt-20">
                        <div class="a-flex-rfsc send-user" v-for="(item,index) in groupItem.groupUsers" :key="index">
                            <span class="send-user-name">{{ item.userName }} {{ item.mobile }} （{{ item.companyName }}）</span>
                            <!-- <span class="send-user-line"></span> -->
                            <i class="el-icon-close a-c-white" @click="removeUserIsGlobal(index)"></i>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="nextStep" v-if="step == 1">下一步</el-button>
                <el-button type="primary"  @click="submit" v-if="step == 2">提交</el-button>
            </div>
        </el-dialog>
        <choose-user ref="chooseUser" @success="handleChooseUser"></choose-user>
    </div>
</template>

<script>
    import {
		CodeToText,
	} from 'element-china-area-data'
    import chooseUser from './chooseUser.vue';
    export default {
        components: {
            chooseUser
        },
        data() {
            return {
                step: 1,
                dialogVisible: false,
                phone: '',
                form: {
                    provinceCode: '',
                    cityCode: '',
                    areaCode: '',
                    company: '',
                    income: '',
                    station: '',
                },
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList,
                    method: "post",
                    params: {
                        queryCompanyId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                optionsStation: {
                    url: this.$Config.apiUrl.getStationInfoList,
                    method: "post",
                    params: {
                        incomeId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "searchKey",  //incomeDistriId
                },//站点数据
                groupItem: {
                    provinceCode: '',
                    cityCode: '',
                    areaCode: '',
                    provinceName: '',
                    cityName: '',
                    areaName: '',
                    regionName: '',
                    companyId: '',
                    incomeId: '',
                    stationId: '',
                    companyName: '',
                    incomeName: '',
                    stationName: '',
                    groupUsers: [],
                },
                groupUsers: [],
                localId: ''
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form']){
                    this.$refs['form'].resetFields()
                }
                if(val) {
                    this.step = 1
                    this.$nextTick(()=>{
                        if(this.form.provinceCode){
                            this.$refs['districtChoose'].values = [this.form.provinceCode]
                        }
                        if(this.form.cityCode){
                            this.$refs['districtChoose'].values = [this.form.provinceCode, this.form.cityCode]
                        }
                        if(this.form.areaCode){
                            this.$refs['districtChoose'].values = [this.form.provinceCode, this.form.cityCode, this.form.areaCode]
                        }
                    })
                }else{
                    this.form = {
                        provinceCode: '',
                        cityCode: '',
                        areaCode: '',
                        company: '',
                        income: '',
                        station: '',
                    }
                    this.phone = ''
                    this.groupUsers = []
                }
            },
            'form.company' :{
                deep: true,
                handler (val) {
                    this.optionsincomeDistri.params.queryCompanyId = val.value
                    this.form.income = ''
                }
            },
            'form.income' :{
                deep: true,
                handler (val) {
                    this.optionsStation.params.incomeId = val.value
                    this.form.station = ''
                }
            }
        },
        methods:{
            nextStep () {
                if(!this.form.provinceCode && !this.form.company && !this.form.income && !this.form.station){
                    this.$message.error('请至少选择一个范围条件')
                    return
                }
                let provinceName = this.form.provinceCode?CodeToText[this.form.provinceCode]:''
                let cityName = this.form.cityCode?CodeToText[this.form.cityCode]:''
                let areaName = this.form.areaCode?CodeToText[this.form.areaCode]:''
                this.groupItem = {
                    provinceCode: this.form.provinceCode,
                    cityCode: this.form.cityCode,
                    areaCode: this.form.areaCode,
                    provinceName: provinceName,
                    cityName: cityName,
                    areaName: areaName,
                    regionName: provinceName + 
                            (cityName ? '/' + cityName : '') +  
                            (areaName ? '/' + areaName : ''),
                    companyId: this.form.company?this.form.company.value:'',
                    incomeId: this.form.income?this.form.income.value:'',
                    stationId: this.form.station?this.form.station.value:'',
                    companyName: this.form.company?this.form.company.label:'',
                    incomeName: this.form.income?this.form.income.label:'',
                    stationName: this.form.station?this.form.station.label:'',
                    groupUsers: this.groupUsers?this.groupUsers:[],
                }
                this.step++
            },
            addUser () {
                if(!this.phone){
                    this.$message.error('请先填写手机号')
                    return
                }
                this.$Axios._post({
                    url: this.$Config.apiUrl.checkUser,
                    method: "post",
                    params: {
                        mobile: this.phone
                    },
                })
                .then(res=>{
                    if (res.result.data && res.result.data.length) {
                        let user
                        if(this.groupItem.companyId){
                            user = res.result.data.find(re=>{
                                return re.companyId == this.groupItem.companyId
                            })
                            if(!user){
                                this.$confirm('该商户下未查询到此用户，是否去添加用户？', '温馨提示', {
                                    confirmButtonText: '确定',
                                    cancelButtonText: '取消',
                                    type: 'warning'
                                }).then(_=>{
                                    this.$router.push({
                                        path: '/staffManage/staff-add'
                                    })
                                }).catch(_=>{})
                                return
                            }
                        }
                        if(res.result.data.length){
                            let userList = res.result.data.map(item=>{
                                return {
                                    ...item,
                                    alertMessageMethodList: item.alertMessageMethodList 
                                    ? item.alertMessageMethodList 
                                    : [
                                        { messageType: 1, checkSuccess: 3, delayMinutes: 0 },
                                        { messageType: "", checkSuccess: 1, delayMinutes: "" }
                                    ]
                                }
                            })
                            this.$refs['chooseUser'].tableData = userList
                            if(userList.length == 1) {
                                this.$refs['chooseUser'].selectData = userList
                                this.$refs['chooseUser'].step = 2
                            }
                            this.$refs['chooseUser'].dialogVisible = true
                            return
                        }else{
                            user = res.result.data[0]
                        }
                        let userItem = this.groupItem.groupUsers.find(res=>{
                            return res.mobile == user.mobile && res.companyId == user.companyId
                        })
                        if(userItem){
                            this.$message.error('已添加该用户')
                            return
                        }
                        this.groupItem.groupUsers.push({
                            mobile: user.mobile,
                            userId: user.userId,
                            userName: user.userName,
                            companyId: user.companyId,
                            companyName: user.companyName
                        })
                        this.phone = ''
                    } else {
                        this.$confirm('未查询到用户，是否去添加用户？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$router.push({
                                path: '/staffManage/staff-add'
                            })
                        }).catch(_=>{})
                    }
                })
                .catch(err=>{

                })
            },
            // 选择商户用户
            handleChooseUser (datas) {
                datas.map(item=>{
                    let targetIndex = this.groupItem.groupUsers.findIndex(res=>{
                        return res.mobile == item.mobile && res.companyId == item.companyId
                    })
                    if(targetIndex != -1) {
                        this.groupItem.groupUsers.splice(targetIndex,1)
                    }
                    this.groupItem.groupUsers.push({
                        mobile: item.mobile,
                        userId: item.userId,
                        userName: item.userName,
                        companyId: item.companyId,
                        companyName: item.companyName,
                        alertMessageMethodList: item.alertMessageMethodList
                    })
                })
                // this.groupItem.groupUsers = this.uniqueArr(this.groupItem.groupUsers)
                this.phone = ''
            },
            removeUserIsGlobal (index) {
                this.groupItem.groupUsers.splice(index,1)
            },
            submit () {
                this.$emit('rangeChange',this.groupItem)
                this.dialogVisible = false    
                this.step = 1
                this.form = {
                    provinceCode: '',
                    cityCode: '',
                    areaCode: '',
                    company: '',
                    income: '',
                    station: '',
                }
                this.phone = ''
                this.groupUsers = []
            },
            //数组去重
            uniqueArr (arr) {
                try{
                    for(var i=0; i<arr.length; i++){
                        for(var j=i+1; j<arr.length; j++){
                            if(arr[i].mobile==arr[j].mobile && arr[i].companyId==arr[j].companyId){         //第一个等同于第二个，splice方法删除第二个
                                arr.splice(j,1);
                                j--;
                            }
                        }
                    }
                    return arr;
                }catch(err){
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .rangeName{
        min-width: 100px;
    }
    .send-user{
        background: #53A8FF;
        color: #ffffff;
        border-radius: 4px;
        padding: 0 10px;
        margin: 0 10px 10px 0;
        .send-user-line{
            width: 1px;
            height: 16px;
            // background: #ffffff;
            margin: 0 5px;
        }
    }
</style>